import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const Row = props => {
  const {
    value,
    columns,
    setDataDelete,
    setIsDeleteOpen,
    setDataEdit,
    setIsEditOpen,
    mode,
  } = props

  const handleEditModalOpen = () => {
    setIsEditOpen(true)
    setDataEdit(value)
  }
  const handleDeleteModalOpen = () => {
    setDataDelete({ id: columns.id })
    setIsDeleteOpen(true)
  }

  let rowActions
  if (mode === 'default') {
    rowActions = (
      <>
        <TableCell align='center'>
          <IconButton size='small' onClick={handleEditModalOpen}>
            <EditIcon />
          </IconButton>
        </TableCell>

        <TableCell align='center'>
          <IconButton size='small' onClick={handleDeleteModalOpen}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </>
    )
  }

  return (
    <TableRow>
      {columns.map(column => (
        // Add a random value besides the Key to avoid conflicts

        <TableCell
          key={'CELLKEY: ' + value.id + ' ' + Math.random()}
          align={column.align}>
          {value[column.key]}
        </TableCell>
      ))}

      {rowActions}
    </TableRow>
  )
}

export default Row
