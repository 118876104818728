import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '../../../components/Select/Select'
import arealist from '../../../data/arealist.json'
import userlist from '../../../data/userlist.json'

const typestep = [
    {
        id: 1,
        name: "Pregão"
    },
    {
        id: 2,
        name: "SRP"
    },
    {
        id: 3,
        name: "SL"
    }
]

function DemandModalEdit({ isOpen, setIsOpen, data, setData }) {

    const handleModalClose = () => {
        setIsOpen(false)
    }

    const handleDataPut = () => {
        console.log(data);

        setIsOpen(false);
    }

    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        },)
    }

    return (
        <Dialog open={isOpen} onClose={handleModalClose} fullWidth maxWidth='md'>

            <DialogTitle>Editar demanda</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                <Grid item xs={6}>
                        <TextField
                            name='objeto'
                            label='Objeto'
                            fullWidth
                            variant='outlined'
                            value={data.objeto}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            jsonList={typestep}
                            nameInput="name"
                            labelName="Pregão/SRP/SL"
                            data={data}
                            setData={setData}
                            jsonObject="stepsType"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            jsonList={arealist}
                            nameInput="area"
                            labelName="Área"
                            data={data}
                            setData={setData}
                            jsonObject="area"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            jsonList={userlist}
                            nameInput="name"
                            labelName="Servidor Requisitante"
                            data={data}
                            setData={setData}
                            jsonObject="userRequeriment"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            jsonList={userlist}
                            nameInput="name"
                            labelName='Servidor Responsável'
                            data={data}
                            setData={setData}
                            jsonObject="userAdmin"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="dataDemand"
                            label="Data de Envio"
                            type="date"
                            fullWidth
                            variant="outlined"
                            value={data.dataDemand}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="dataServer"
                            label="Data da Distribuição"
                            type="date"
                            variant="outlined"
                            fullWidth
                            value={data.dataServer}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="dataPregoeiro"
                            label="Data de Designação"
                            type="date"
                            variant="outlined"
                            fullWidth
                            value={data.dataPregoeiro}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='description'
                            label='Descrição'
                            variant='outlined'
                            multiline={true}
                            fullWidth
                            rowsMax={4}
                            rows={4}
                            value={data.description}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleModalClose} color='default'>
                    Fechar
                </Button>
                <Button onClick={handleDataPut} color='default'>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DemandModalEdit;