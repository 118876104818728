import React from 'react'

import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

const Header = props => {
  const { columns } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell />

        {columns.map(column => (
          <TableCell
            style={{ width: column.width }}
            key={column.key}
            align={column.align}>
            {column.label}
          </TableCell>
        ))}

        <TableCell style={{ width: '50px' }} align='center'>
          Editar
        </TableCell>

        <TableCell style={{ width: '50px' }} align='center'>
          Remover
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default Header
