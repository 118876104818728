import React from 'react'

import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'

const Footer = props => {
  const { page, rowsPerPage } = props

  const handleChangePage = (_e, newPage) => {
    page.setPage(newPage)
  }

  const handleChangeRowsPerPage = e => {
    rowsPerPage.setRowsPerPage(parseInt(e.target.value, 10))
    page.setPage(0)
  }

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={-1}
          rowsPerPage={rowsPerPage.rowsPerPage}
          page={page.page}
          labelDisplayedRows={({ from, to }) => `${from}-${to}`}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableRow>
    </TableFooter>
  )
}

export default Footer
