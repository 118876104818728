import { makeStyles } from '@material-ui/core/styles'

export const BaseContainer = makeStyles({
  container: {
    display: 'flex',
  },
  containerGrid: {
    margin: '2%',
  },
})
