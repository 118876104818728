import React from 'react'

import DialogTitle from '@material-ui/core/DialogTitle'

const ModalTitle = props => {
  const { type, options } = props

  const HandleTitle = () => {
    let title

    if (options.length > 0) {
      options.forEach(option => {
        if (type === option.type) {
          title = option.title
        }
      })
    }
    return title
  }

  return <DialogTitle>{HandleTitle()}</DialogTitle>
}

ModalTitle.defaultProps = {
  type: '',
  options: [],
}

export default ModalTitle
