import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from './pages/Login/Login'
import Usuarios from './pages/Users/Users'
import Areas from './pages/Area/Area'
import Produtos from './pages/Product/Product'
import Steps from './pages/Steps/Steps'
import Contract from './pages/Contracts/Contracts'
import Demand from './pages/Demand/Demand'
import ServiceType from './pages/ServiceType/ServiceType'
import Provider from './pages/Provider/Provider'

function Routes() {
  return (
    <Router>
      <div className='App'>
        <Switch>
          <Route path='/' exact component={Login} />
          <Route path='/usuarios' component={Usuarios} />
          <Route path='/areas' component={Areas} />
          <Route path='/produtos' component={Produtos} />
          <Route path='/fases' component={Steps} />
          <Route path='/contratos' component={Contract} />
          <Route path='/demandas' component={Demand} />
          <Route path='/servicos' component={ServiceType} />
          <Route path='/fornecedores' component={Provider} />
        </Switch>
      </div>
    </Router>
  )
}
export default Routes
