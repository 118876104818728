import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'

import serviceType from '../../data/servicetype.json'
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader'
import DashboardTable from '../../components/DashboardTable/DashboardTable'
import TableActions from '../../components/TableActions/TableActions'
import ServiceTypeModalNew from '../ServiceType/ServiceTypeModalNew/ServiceTypeModalNew'
import ServiceTypeModalEdit from '../ServiceType/ServiceTypeModalEdit/ServiceTypeModalEdit'
import ServiceTypeModalDelete from './ServiceTypeModalDelete/ServiceTypeModalDelete'
import Menu from '../../components/Menu/Menu'

import { BaseContainer } from '../../assets/BaseStyles'

const tableColumns = [
  {
    label: 'Id',
    key: 'id',
    align: 'center',
    width: '5%',
  },
  {
    label: 'Tipo de Serviço',
    key: 'serviceType',
    align: 'left',
    width: '',
  },
]
const fetchData = () => {
  return serviceType
}

function ServiceType() {
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [dataGET, setDataGET] = useState([])
  const [dataPOST, setDataPOST] = useState({
    serviceType: '',
  })
  const [dataDELETE, setDataDELETE] = useState({})
  // const [delData, setDelData] = useState({})
  const [dataPUT, setDataPUT] = useState({
    serviceType: '',
  })

  const handleModalNewOpen = () => setIsModalOpen(true)

  // Fetch data when component is rendered
  useEffect(() => {
    setDataGET(fetchData())
  }, [])

  /* BaseClass is a set of standard styles */
  const BaseClass = BaseContainer()

  return (
    <div className={BaseClass.container}>
      <Menu />

      <Grid container component='section' className={BaseClass.containerGrid}>
        <Grid item xs={12} className='header'>
          <DashboardHeader
            title='Tipos de serviço'
            subTitle='Lista de tipos de serviço do sistema'
          />
        </Grid>

        <Grid item xs={12}>
          <TableActions
            btnLabel='Novo Tipo de Serviço'
            btnAction={handleModalNewOpen}
          />
        </Grid>

        <Grid item xs={12} className='service-type-table'>
          <DashboardTable
            columns={tableColumns}
            data={dataGET}
            setDataEdit={setDataPUT}
            setIsEditOpen={setIsModalEditOpen}
            setDataDelete={setDataDELETE}
            setIsDeleteOpen={setIsModalDeleteOpen}
          />
        </Grid>
      </Grid>

      <ServiceTypeModalNew
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        data={dataPOST}
        setData={setDataPOST}
      />

      <ServiceTypeModalEdit
        isOpen={isModalEditOpen}
        setIsOpen={setIsModalEditOpen}
        data={dataPUT}
        setData={setDataPUT}
      />

      <ServiceTypeModalDelete
        isOpen={isModalDeleteOpen}
        setIsOpen={setIsModalDeleteOpen}
        data={dataDELETE}
      />
    </div>
  )
}

export default ServiceType
