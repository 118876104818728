import React from 'react'
import ReactDOM from 'react-dom'

import Router from './router'

import './index-style.scss'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale'

const theme = createMuiTheme(
  {
    shape: {
      borderRadius: 0,
    },
  },
  ptBR
)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router />
  </ThemeProvider>,
  document.getElementById('root')
)
