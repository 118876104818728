import React, { useState } from 'react'

import TablePagination from '@material-ui/core/TablePagination'
import TableFooter from '@material-ui/core/TableFooter'

import { makeStyles } from '@material-ui/core/styles'

import {
  Box,
  Paper,
  Typography,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
  Collapse,
} from '@material-ui/core'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function Row({
  row,
  setDataEdit,
  setIsEditOpen,
  setDataDelete,
  setIsDeleteOpen,
}) {
  const [open, setOpen] = useState(false)
  const classes = useRowStyles()

  const handleEditModalOpen = () => {
    setDataEdit(row)
    setIsEditOpen(true)
  }

  const handleDeleteModalOpen = () => {
    setDataDelete({ id: row.id })
    setIsDeleteOpen(true)
  }

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell style={{ width: '30px' }}>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align='center' style={{ width: '10%' }}>
          {row.id}
        </TableCell>

        <TableCell align='left'>{row.area}</TableCell>

        <TableCell align='center' style={{ width: '50px' }}>
          <IconButton size='small' onClick={handleEditModalOpen}>
            <EditIcon />
          </IconButton>
        </TableCell>

        <TableCell align='center' style={{ width: '50px' }}>
          <IconButton size='small' onClick={handleDeleteModalOpen}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Typography variant='h6' gutterBottom component='div'>
                Descrição
              </Typography>
              <p>{row.description}</p>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const CollapsibleTable = ({
  data,
  columns,
  setDataEdit,
  setIsEditOpen,
  setDataDelete,
  setIsDeleteOpen,
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />

              {columns.map(column => (
                <TableCell key={column.title} align={column.align}>
                  {column.title}
                </TableCell>
              ))}

              <TableCell align='center'>Editar</TableCell>
              <TableCell align='center'>Remover</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map(row => (
              <Row
                key={row.id}
                row={row}
                setDataEdit={setDataEdit}
                setIsEditOpen={setIsEditOpen}
                setDataDelete={setDataDelete}
                setIsDeleteOpen={setIsDeleteOpen}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}

export default CollapsibleTable
