import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'

import demandlist from '../../data/demandlist.json'
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader'
import DashboardTable from '../../components/DashboardTable/DashboardTable'
import TableActions from '../../components/TableActions/TableActions'
import Menu from '../../components/Menu/Menu'
import DemandModalNew from './DemandModalNew/DemandModalNew'
import DemandModalEdit from './DemandModalEdit/DemandModalEdit'
import ModalDelete from '../../components/ModalDelete/ModalDelete'

import { BaseContainer } from '../../assets/BaseStyles'

const tableColumns = [
  {
    label: 'Id',
    key: 'id',
    align: 'center',
    width: '5%',
  },
  {
    label: 'Objeto',
    key: 'objeto',
    align: 'left',
    width: '',
  },
  {
    label: 'SRP/Pregão/SL',
    key: 'stepsType',
    align: 'center',
    width: '',
  },
  {
    label: 'Area',
    key: 'area',
    align: 'center',
    width: '',
  },

  {
    label: 'Requisitante',
    key: 'userRequeriment',
    align: 'center',
    width: '',
  },
  {
    label: 'Coordenador',
    key: 'userAdmin',
    align: 'center',
    width: '',
  },
  {
    label: 'Data de Demanda',
    key: 'dataDemand',
    align: 'center',
    width: '',
  },
  {
    label: 'Data de Destribuição',
    key: 'dataServer',
    align: 'center',
    width: '',
  },
  {
    label: 'Data Pregoeiro',
    key: 'dataPregoeiro',
    align: 'center',
    width: '',
  },
  {
    label: 'Descrição',
    key: 'description',
    align: 'center',
    width: '',
  },
]

function fetchData() {
  return demandlist
}

function Demand() {
  const [dataGET, setDataGET] = useState([])
  const [dataPOST, setDataPOST] = useState({
    objeto: '',
    stepstype: '',
    area: '',
    description: '',
    userRequeriment: '',
    userAdmin: '',
    dataDemand: '',
    dataServer: '',
    dataPregoeiro: '',
  })
  const [dataPUT, setDataPUT] = useState({
    id: '',
    objeto: '',
    stepstype: '',
    area: '',
    description: '',
    userRequeriment: '',
    userAdmin: '',
    dataDemand: '',
    dataServer: '',
    dataPregoeiro: '',
  })
  const [dataDELETE, setDataDELETE] = useState({
    id: '',
  })

  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)

  const handleModalOpen = () => setIsModalOpen(true)

  /* BaseClass is a set of standard styles */
  const BaseClass = BaseContainer()

  useEffect(() => {
    setDataGET(fetchData())
  }, [])

  return (
    <div className={BaseClass.container}>
      <Menu />
      <Grid container component='sections' className={BaseClass.containerGrid}>
        <Grid item xs={12}>
          <DashboardHeader
            title='Demanda'
            subTitle='Lista de demandas do sistema'
          />
        </Grid>

        <Grid item xs={12}>
          <TableActions btnLabel='Nova Demanda' btnAction={handleModalOpen} />
        </Grid>

        <Grid item xs={12}>
          <DashboardTable
            columns={tableColumns}
            data={dataGET}
            setDataEdit={setDataPUT}
            setIsEditOpen={setIsModalEditOpen}
            setDataDelete={setDataDELETE}
            setIsDeleteOpen={setIsModalDeleteOpen}
          />
        </Grid>
      </Grid>

      <DemandModalNew
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        data={dataPOST}
        setData={setDataPOST}
      />

      <DemandModalEdit
        isOpen={isModalEditOpen}
        setIsOpen={setIsModalEditOpen}
        data={dataPUT}
        setData={setDataPUT}
      />

      <ModalDelete
        textTitle='Deseja remover essa Demanda?'
        isOpen={isModalDeleteOpen}
        setIsOpen={setIsModalDeleteOpen}
        data={dataDELETE}
      />
    </div>
  )
}

export default Demand
