import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'

import CollapsibleTable from './CollapsibleTable/CollapsibleTable'
import AreaModalNew from './AreaModalNew/AreaModalNew'
import AreaModalEdit from './AreaModalEdit/AreaModalEdit'
import AreaAlertDelete from './AreaAlertDelete/AreaAlertDelete'
import arealist from '../../data/arealist.json'

import DashboardHeader from '../../components/DashboardHeader/DashboardHeader'
import TableActions from '../../components/TableActions/TableActions'
import Menu from '../../components/Menu/Menu'

import { BaseContainer } from '../../assets/BaseStyles'

const fetchData = () => arealist

const tableColumns = [
  { title: 'Id', align: 'center' },
  { title: 'Area', align: 'left' },
]

const Area = () => {
  const [dataGET, setDataGET] = useState([])
  const [dataPOST, setDataPOST] = useState({
    area: '',
    description: '',
  })
  const [dataPUT, setDataPUT] = useState({
    id: '',
    area: '',
    description: '',
  })
  const [dataDELETE, setDataDELETE] = useState({
    id: '',
  })
  // Modal States
  const [isModalNewOpen, setIsModalNewOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)

  // GET data when component is mounted
  useEffect(() => {
    setDataGET(fetchData())
  }, [])

  /* BaseClass is a set of standard styles */
  const BaseClass = BaseContainer()

  const handleModalNewOpen = () => setIsModalNewOpen(true)

  return (
    <div className={BaseClass.container}>
      <Menu />

      <Grid container component='section' className={BaseClass.containerGrid}>
        <Grid item xs={12} className='header'>
          <DashboardHeader title='Areas' subTitle='Lista de areas do sistema' />
        </Grid>

        <Grid item xs={12}>
          <TableActions btnLabel='Nova Area' btnAction={handleModalNewOpen} />
        </Grid>

        <Grid item xs={12} className='area-table'>
          <CollapsibleTable
            data={dataGET}
            columns={tableColumns}
            setDataEdit={setDataPUT}
            setIsEditOpen={setIsModalEditOpen}
            setDataDelete={setDataDELETE}
            setIsDeleteOpen={setIsModalDeleteOpen}
          />
        </Grid>
      </Grid>

      <AreaModalNew
        isOpen={isModalNewOpen}
        setIsOpen={setIsModalNewOpen}
        data={dataPOST}
        setData={setDataPOST}
      />

      <AreaModalEdit
        isOpen={isModalEditOpen}
        setIsOpen={setIsModalEditOpen}
        data={dataPUT}
        setData={setDataPUT}
      />

      <AreaAlertDelete
        isOpen={isModalDeleteOpen}
        setIsOpen={setIsModalDeleteOpen}
        data={dataDELETE}
      />
    </div>
  )
}

export default Area
