import React from 'react'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  title: {
    marginBottom: '15px',
  },
  divider: {
    margin: '15px 0',
  },
})

const DashboardHeader = props => {
  const { title, subTitle } = props

  const classes = useStyles()

  return (
    <>
      <Typography variant='h5' component='h1' className={classes.title}>
        {title}
      </Typography>

      <Typography variant='subtitle2' component='h2'>
        {subTitle}
      </Typography>

      <Divider variant='fullWidth' className={classes.divider} />
    </>
  )
}

export default DashboardHeader
