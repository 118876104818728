import React, { useState } from 'react'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import Row from './sub-components/Row'

import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'

const DashboardTable = ({
  columns,
  data,
  setDataDelete,
  setIsDeleteOpen,
  setDataEdit,
  setIsEditOpen,
  mode,
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  let actionHeader
  let pagination
  if (mode === 'default') {
    actionHeader = (
      <>
        <TableCell align='center' style={{ width: '50px' }}>
          Editar
        </TableCell>

        <TableCell align='center' style={{ width: '50px' }}>
          Remover
        </TableCell>
      </>
    )
    pagination = (
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.label + Math.random()}
                align={column.align}
                style={{ width: column.width }}>
                {column.label}
              </TableCell>
            ))}

            {actionHeader}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map(value => (
            <Row
              key={value.id}
              value={value}
              columns={columns}
              setDataDelete={setDataDelete}
              setIsDeleteOpen={setIsDeleteOpen}
              setDataEdit={setDataEdit}
              setIsEditOpen={setIsEditOpen}
              mode={mode}
            />
          ))}
        </TableBody>
        {pagination}
      </Table>
    </TableContainer>
  )
}

DashboardTable.defaultProps = {
  mode: 'default',
  columns: [{}],
  data: [{}],
}

export default DashboardTable
