import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'

import DashboardHeader from '../../components/DashboardHeader/DashboardHeader'
import TableActions from '../../components/TableActions/TableActions'
import DashboardCollapsibleTable from '../../components/DashboardCollapsibleTable/DashboardCollapsibleTable'
import Typography from '@material-ui/core/Typography'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import contractData_5 from '../../data/contracts/contracts_5.json'
import contractData_10 from '../../data/contracts/contracts_10.json'
import contractData_25 from '../../data/contracts/contracts_25.json'

import ModalTitle from '../../components/ModalTitle/ModalTitle'
import Menu from '../../components/Menu/Menu'
import ModalContent from './sub-components/ModalContent'

import { BaseContainer } from '../../assets/BaseStyles'

const columns = [
  {
    label: 'Id',
    key: 'id',
    align: 'center',
    width: '50px',
  },
  {
    label: 'Item',
    key: 'item',
    align: 'left',
    width: '',
  },
  {
    label: 'Tipo do Item',
    key: 'itemType',
    align: 'center',
    width: '',
  },
  {
    label: 'Tipo de Contrato',
    key: 'contractType',
    align: 'center',
    width: '',
  },
  {
    label: 'Responsavel',
    key: 'assignedTo',
    align: 'left',
    width: '',
  },
  {
    label: 'Data de Inicio',
    key: 'startDate',
    align: 'center',
    width: '',
  },
  {
    label: 'Data de Finalização',
    key: 'endDate',
    align: 'center',
    width: '',
  },
]

const fetchData = rowsPerPage => {
  switch (rowsPerPage) {
    case 5:
      // console.log(contractData_5)
      return contractData_5

    case 10:
      // console.log(contractData_10)
      return contractData_10

    case 25:
      // console.log(contractData_25)
      return contractData_25

    default:
      break
  }
}

const Contracts = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [dataGET, setDataGET] = useState([])
  const [dataDETAILED, setDataDETAILED] = useState({})

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState('')

  /* BaseClass is a set of standard styles */
  const BaseClass = BaseContainer()

  useEffect(() => {
    setDataGET(fetchData(rowsPerPage))
    console.log(
      `GET: http://localhost:0000/api/contracts?page=${page}&size=${rowsPerPage}`
    )
  }, [page, rowsPerPage])

  const handleModalOpen = modalType => {
    setModalType(modalType)
    setIsModalOpen(true)
  }

  const editSteps = () => {
    console.log(
      `PUT: http://localhost:0000/api/contracts/${dataDETAILED.id}/steps`
    )
  }

  const editContract = () => {
    console.log(`PUT: http://localhost:0000/api/contracts/${dataDETAILED.id}`)
  }

  const handleModalClose = () => setIsModalOpen(false)
  const handleModalSave = () => {
    switch (modalType) {
      case 'detailed':
        editSteps()
        break

      case 'edit':
        editContract()
        break

      default:
        break
    }
  }

  const ModalTitleOptions = [
    { type: 'detailed', title: 'Contrato Detalhado' },
    { type: 'edit', title: 'Edição de Contrato' },
    { type: 'delete', title: 'Deseja Deletar o Contrato?' },
  ]

  return (
    <div className={BaseClass.container}>
      <Menu />
      <Grid container component='section' className={BaseClass.containerGrid}>
        <Grid item xs={12}>
          <DashboardHeader
            title='Contratos'
            subTitle='Lista de contratos do sistema'
          />
        </Grid>

        <Grid item xs={12}>
          <TableActions btn={false} />
        </Grid>

        <Grid item xs={12} className='user-table'>
          <DashboardCollapsibleTable
            columns={columns}
            data={dataGET}
            page={{
              page: page,
              setPage: setPage,
            }}
            rowsPerPage={{
              rowsPerPage: rowsPerPage,
              setRowsPerPage: setRowsPerPage,
            }}
            handleModal={handleModalOpen}
            setDataDETAILED={setDataDETAILED}
            collapsibleContent={row => (
              <>
                <Typography variant='h6' gutterBottom component='div'>
                  Observações
                </Typography>
                <p>{row.observations}</p>
              </>
            )}
          />
        </Grid>
      </Grid>

      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        fullWidth
        maxWidth='lg'>
        <ModalTitle type={modalType} options={ModalTitleOptions} />

        <ModalContent
          type={modalType}
          data={dataDETAILED}
          setData={setDataDETAILED}
        />

        <DialogActions>
          <Button onClick={handleModalClose}>Cancelar</Button>
          <Button onClick={handleModalSave}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Contracts
