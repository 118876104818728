import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

const ServiceTypeModalEdit = ({ isOpen, setIsOpen, data, setData }) => {
  const handleModalClose = () => {
    setIsOpen(false)
  }

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const handleDataPut = () => {
    console.log(data)

    setIsOpen(false)
  }

  return (
    <Dialog open={isOpen} onClose={handleModalClose} fullWidth maxWidth='md'>
      <DialogTitle>Editar Tipo de Serviço</DialogTitle>

      <DialogContent>
        <Grid container spacing={2} >
        <Grid item xs={12}>
                        <TextField
                            name='serviceType'
                            label='Tipo de Serviço'
                            fullWidth
                            variant='outlined'
                            value={data.serviceType}
                            onChange={handleChange}
                        />
                    </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleModalClose} color='default'>
          Fechar
        </Button>

        <Button onClick={handleDataPut} color='default' autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ServiceTypeModalEdit
