import React, { useState } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '../../../components/Select/Select'
import arealist from '../../../data/arealist.json'


function UsersModalNew({ isOpen, setIsOpen, data, setData }) {

    const [confirmSenha, setConfirmSenha] = useState("");

    const handleClose = () => {
        setData({
            name: '',
            area: '',
            status: '',
            email: '',
            senha: '',
            ramal: '',
            telefone: ''
        })

        setConfirmSenha('');

        setIsOpen(false)
    }

    const handleDataPost = () => {

        if (confirmSenha !== data.senha) {
            alert("senhas diferentes")
        } else {
            console.log(data);
            handleClose();
        }

    }

    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Dialog name='modalNew' open={isOpen} onClose={handleClose} fullWidth maxWidth='md'>

            <DialogTitle>Cadastro de Usuários</DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name='name'
                            label='Nome'
                            fullWidth
                            variant='outlined'
                            value={data.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='email'
                            label='E-mail'
                            variant='outlined'
                            multiline={true}
                            fullWidth
                            value={data.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='ramal'
                            label='Ramal'
                            fullWidth
                            variant='outlined'
                            value={data.ramal}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            jsonList={arealist}
                            labelName="Área"
                            nameInput="area"
                            data={data}
                            setData={setData}
                            jsonObject="area"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='telefone'
                            label='Telefone'
                            fullWidth
                            variant='outlined'
                            value={data.telefone}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='senha'
                            label='Senha'
                            fullWidth
                            variant='outlined'
                            value={data.senha}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='confirmSenha'
                            label='Confirmar Senha'
                            fullWidth
                            variant='outlined'
                            value={confirmSenha}
                            onChange={e => setConfirmSenha(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button name='modalNew' onClick={handleClose} color='default'>
                    Fechar
                </Button>

                <Button
                    name='modalNew'
                    onClick={handleDataPost}
                    color='default'
                    autoFocus>
                    Salvar
                </Button>
            </DialogActions>

        </Dialog>
    );


}
export default UsersModalNew