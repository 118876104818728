import React from 'react'

import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'

import Header from './sub-components/Header'
import Footer from './sub-components/Footer'
import Row from './sub-components/Row'

const DashboardCollapsibleTable = props => {
  const {
    columns,
    data,
    page,
    rowsPerPage,
    handleModal,
    setDataDETAILED,
    collapsibleContent,
  } = props

  return (
    <TableContainer component={Paper}>
      <Table>
        <Header columns={columns} />

        <TableBody>
          {data.map(row => (
            <Row
              key={row.id}
              columns={columns}
              data={row}
              onClick={handleModal}
              setRowDataDetailed={setDataDETAILED}>
              {collapsibleContent(row)}
            </Row>
          ))}
        </TableBody>

        <Footer page={page} rowsPerPage={rowsPerPage} />
      </Table>
    </TableContainer>
  )
}

DashboardCollapsibleTable.defaultProps = {
  columns: [],
  data: [],
}
export default DashboardCollapsibleTable
