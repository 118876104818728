import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import providerlist from '../../data/providerlist.json'
import DashboardTable from '../../components/DashboardTable/DashboardTable'
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader'
import TableActions from '../../components/TableActions/TableActions'
import Menu from '../../components/Menu/Menu'
import ProviderModalNew from '../Provider/ProviderModalNew/Provider'
import ProviderModalDelete from '../Provider/ProviderModalDelete/Provider'
import ProviderModalEdit from './ProviderModalEdit/Provider'

import { BaseContainer } from '../../assets/BaseStyles'

const tableColumns = [
  {
    label: 'Id',
    key: 'id',
    align: 'center',
    width: '',
  },
  {
    label: 'Fornecedor',
    key: 'fornecedor',
    align: 'center',
    width: '',
  },
  {
    label: 'CNPJ',
    key: 'cnpj',
    align: 'center',
    width: '',
  },
]
function fetchData() {
  return providerlist
}

const Provider = () => {
  const [dataGET, setDataGET] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleModalOpen = () => setIsModalOpen(true)
  const [dataPOST, setDataPOST] = useState({
    id: '',
    fornecedor: '',
    cnpj: '',
  })
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [dataDELETE, setDataDELETE] = useState({})
  const [dataPUT, setDataPUT] = useState({
    id: '',
    fornecedor: '',
    cnpj: '',
  })

  useEffect(() => {
    setDataGET(fetchData())
  }, [])

  /* BaseClass is a set of standard styles */
  const BaseClass = BaseContainer()

  return (
    <div className={BaseClass.container}>
      <Menu />
      <Grid container className={BaseClass.containerGrid}>
        <Grid item xs={12} classNome='provider-header'>
          <DashboardHeader
            title='Fornecedores'
            subTitle='Lista de fornecedores do sistema'
          />
        </Grid>
        <Grid item xs={12}>
          <TableActions
            btnLabel='Novo Fornecedor'
            btnAction={handleModalOpen}
          />
        </Grid>
        <Grid item xs={12} className='provider-table'>
          <DashboardTable
            columns={tableColumns}
            data={dataGET}
            setDataEdit={setDataPUT}
            setIsEditOpen={setIsModalEditOpen}
            setDataDelete={setDataDELETE}
            setIsDeleteOpen={setIsModalDeleteOpen}
          />
        </Grid>
      </Grid>

      <ProviderModalNew
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        data={dataPOST}
        setData={setDataPOST}
      />
      <ProviderModalDelete
        isOpen={isModalDeleteOpen}
        setIsOpen={setIsModalDeleteOpen}
        data={dataDELETE}
      />
      <ProviderModalEdit
        isOpen={isModalEditOpen}
        setIsOpen={setIsModalEditOpen}
        data={dataPUT}
        setData={setDataPUT}
      />
    </div>
  )
}

export default Provider
