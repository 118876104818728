import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '../../../components/Select/Select'
import arealist from '../../../data/arealist.json'

const UsersModalEdit = ({ isOpen, setIsOpen, data, setData }) => {
  const handleModalClose = () => {
    setIsOpen(false)
  }

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const handleDataPut = () => {
    console.log(data)

    setIsOpen(false)
  }

  return (
    <Dialog open={isOpen} onClose={handleModalClose} fullWidth maxWidth='md'>
      <DialogTitle>Editar usuario</DialogTitle>

      <DialogContent>
        <Grid container spacing={2} >
          <Grid item xs={6}>
            <TextField
              name='name'
              label='usuario'
              fullWidth
              variant='outlined'
              value={data.name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name='email'
              label='E-mail'
              fullWidth
              variant='outlined'
              value={data.email}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name='ramal'
              label='Ramal'
              variant='outlined'
              fullWidth
              value={data.ramal}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Select 
              jsonList={arealist}
              labelName="Área"
              nameInput="area"
              data={data}
              setData={setData}
              jsonObject="area"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name='senha'
              label='Senha'
              variant='outlined'
              type='password'
              fullWidth
              value={data.senha}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name='telefone'
              label='Telefone'
              variant='outlined'
              type='tel'
              fullWidth
              value={data.telefone}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleModalClose} color='default'>
          Fechar
        </Button>

        <Button onClick={handleDataPut} color='default' autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UsersModalEdit
