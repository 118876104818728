import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '../../../components/Select/Select'

const category = [
  {
    id: 1,
    name: "Pregão"
  },
  {
    id: 2,
    name: "SRP"
  },
  {
    id: 3,
    name: "SL"
  }
]


const type = [
  {
    id: 1,
    name: "Interno"
  },
  {
    id: 2,
    name: "Externo"
  }
]



const StepsModalEdit = ({ isOpen, setIsOpen, data, setData }) => {
  const handleModalClose = () => {
    setIsOpen(false)
  }

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const handleDataPut = () => {
    console.log(data)

    setIsOpen(false)
  }

  return (
    <Dialog open={isOpen} onClose={handleModalClose} fullWidth maxWidth='md'>
      <DialogTitle>Editar fase</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              name='number'
              label='Número'
              fullWidth
              variant='outlined'
              value={data.number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              name='step'
              label='Fase'
              variant='outlined'
              multiline={true}
              fullWidth
              value={data.step}
              onChange={handleChange}
            />
          </Grid>
          {/* Verificar, acho que os dois abaixo são selects */}
          <Grid item xs={6}>
            <Select
              jsonList={type}
              labelName="Tipo"
              nameInput="name"
              data={data}
              setData={setData}
              jsonObject="type"
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              jsonList={category}
              labelName="Categoria"
              nameInput="name"
              data={data}
              setData={setData}
              jsonObject="category"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleModalClose} color='default'>
          Fechar
        </Button>

        <Button onClick={handleDataPut} color='default' autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default StepsModalEdit
