import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const ProviderModalDelete = ({ isOpen, setIsOpen, data }) => {
    const handleClose = () => setIsOpen(false)

    const handleDelete = () => {
        console.log(data)
        handleClose()
    }

    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='md'>
                <DialogTitle>Deseja remover Fornecedor?</DialogTitle>

                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Uma vez deletado os dados, não sera possivel recupera-los
          </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color='default'>
                        Não
          </Button>
                    <Button onClick={handleDelete} color='default' autoFocus>
                        Sim
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ProviderModalDelete