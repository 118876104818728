import React, { useState } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '../../../components/Select/Select'

const category = [
    {
      id: 1,
      name: "Pregão"
    },
    {
      id: 2,
      name: "SRP"
    },
    {
      id: 3,
      name: "SL"
    }
  ]
  
  
  const type = [
    {
      id: 1,
      name: "Interno"
    },
    {
      id: 2,
      name: "Externo"
    }
  ]



function StepsModalNew({ isOpen, setIsOpen, data, setData }) {

    const [confirmSenha, setConfirmSenha] = useState("");

    const handleClose = () => {
        setData({
            name: '',
            area: '',
            status: '',
            email: '',
            senha: '',
            ramal: '',
            telefone: ''
        })

        setConfirmSenha('');

        setIsOpen(false)
    }

    const handleDataPost = () => {

        if (confirmSenha !== data.senha) {
            alert("senhas diferentes")
        } else {
            console.log(data);
            handleClose();
        }

    }

    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Dialog name='modalNew' open={isOpen} onClose={handleClose} fullWidth maxWidth='md'>

            <DialogTitle>Cadastro de Fase</DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            name='number'
                            label='Número'
                            fullWidth
                            variant='outlined'
                            value={data.number}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            name='step'
                            label='Fase'
                            variant='outlined'
                            multiline={true}
                            fullWidth
                            value={data.step}
                            onChange={handleChange}
                        />
                    </Grid>
                    {/* Verificar, acho que os dois abaixo são selects */}
                    <Grid item xs={6}>
                        <Select
                            jsonList={type}
                            labelName="Tipo"
                            nameInput="name"
                            data={data}
                            setData={setData}
                            jsonObject="type"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            jsonList={category}
                            labelName="Categoria"
                            nameInput="name"
                            data={data}
                            setData={setData}
                            jsonObject="category"
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button name='modalNew' onClick={handleClose} color='default'>
                    Fechar
                </Button>

                <Button
                    name='modalNew'
                    onClick={handleDataPost}
                    color='default'
                    autoFocus>
                    Salvar
                </Button>
            </DialogActions>

        </Dialog>
    );


}
export default StepsModalNew