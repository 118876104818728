import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

const AreaModalNew = ({ isOpen, setIsOpen, data, setData }) => {
  // Resets the form state to be blank than closes the component
  const handleClose = () => {
    setData({
      area: '',
      description: '',
    })

    setIsOpen(false)
  }

  // Compile and send data to the API
  const handleDataPost = () => {
    if (data.area !== '' && data.description !== '') {
      console.log(data)

      handleClose()
    } else {
      console.error('Campos em branco')
    }
  }

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Dialog
      name='modalNew'
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth='md'>
      <DialogTitle>Cadastro de Areas</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name='area'
              label='Area'
              fullWidth
              variant='outlined'
              value={data.area}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name='description'
              label='Descrição'
              variant='outlined'
              multiline={true}
              fullWidth
              rowsMax={4}
              rows={4}
              value={data.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button name='modalNew' onClick={handleClose} color='default'>
          Fechar
        </Button>

        <Button
          name='modalNew'
          onClick={handleDataPost}
          color='default'
          autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AreaModalNew
