import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

function ProviderModalNew({ isOpen, setIsOpen, data, setData }) {

    const handleClose = () => {
        setData({
            fornecedor: '',
            cnpj: ''
        })

        setIsOpen(false)
    }

    const handleDataPost = () => {
        console.log(data);

        setIsOpen(false)
    }

    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Dialog name='modalnew' open={isOpen} onClose={handleClose} fullWidth maxWidth='md'>

            <DialogTitle>Cadastrar uma nova demanda</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            name='fornecedor'
                            label='Fornecedor'
                            fullWidth
                            variant='outlined'
                            value={data.userAdmin}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="cnpj"
                            label="CNPJ"
                            type="number"
                            fullWidth
                            variant="outlined"
                            value={data.dataDemand}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button name='modalnew' onClick={handleClose} color='default'>
                    Fechar
                </Button>
                <Button name='modalnew' onClick={handleDataPost} color='default'>
                    Salvar
                </Button>
            </DialogActions>

        </Dialog>
    );
}


export default ProviderModalNew;