import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '../../../components/Select/Select'
import servicetype from '../../../data/servicetype.json'
import providerlist from '../../../data/providerlist.json'

const ProductModalEdit = ({ isOpen, setIsOpen, data, setData }) => {
  const handleModalClose = () => {
    setIsOpen(false)
  }

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const handleDataPut = () => {
    console.log(data)

    setIsOpen(false)
  }

  return (
    <Dialog open={isOpen} onClose={handleModalClose} fullWidth maxWidth='md'>
      <DialogTitle>Editar Produto</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              label='Id'
              fullWidth
              variant='outlined'
              value={data.id}
              disabled
            />
          </Grid>

          <Grid item xs={10}>
            <TextField
              name='name'
              label='Name'
              fullWidth
              variant='outlined'
              value={data.name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <Select
              jsonList={servicetype}
              labelName="Tipo de Serviço"
              nameInput="serviceType"
              data={data}
              setData={setData}
              jsonObject="typeservice"
            />
          </Grid>

          <Grid item xs={6}>
            <Select
              jsonList={providerlist}
              labelName="Fornecedor"
              nameInput="fornecedor"
              data={data}
              setData={setData}
              jsonObject="fornecedor"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name='description'
              label='Descriçao'
              fullWidth
              variant='outlined'
              value={data.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleModalClose} color='default'>
          Fechar
        </Button>

        <Button onClick={handleDataPut} color='default' autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProductModalEdit
