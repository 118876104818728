import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'

import DashboardHeader from '../../components/DashboardHeader/DashboardHeader'
import DashboardTable from '../../components/DashboardTable/DashboardTable'
import TableActions from '../../components/TableActions/TableActions'
import UsersModalNew from '../Users/UsersModalNew/UsersModalNew'
import UsersModalEdit from '../Users/UsersModalEdit/UsersModalEdit'
import UsersModalDelete from './UsersModalDelete/UsersModalDelete'
import Menu from '../../components/Menu/Menu'

import userData from '../../data/userlist.json'

import { BaseContainer } from '../../assets/BaseStyles'

const tableColumns = [
  {
    label: 'Id',
    key: 'id',
    align: 'center',
    width: '5%',
  },
  {
    label: 'Nome',
    key: 'name',
    align: 'left',
    width: '',
  },
  {
    label: 'Area',
    key: 'area',
    align: 'center',
    width: '',
  },
  {
    label: 'Status',
    key: 'status',
    align: 'center',
    width: '',
  },
  {
    label: 'Email',
    key: 'email',
    align: 'left',
    width: '',
  },
  {
    label: 'Ramal',
    key: 'ramal',
    align: 'center',
    width: '',
  },
  {
    label: 'Telefone',
    key: 'telefone',
    align: 'center',
    width: '',
  },
]
const fetchData = () => {
  return userData
}

function Users() {
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [dataGET, setDataGET] = useState([])
  const [dataPOST, setDataPOST] = useState({
    name: '',
    area: '',
    status: '',
    email: '',
    senha: '',
    ramal: '',
    telefone: '',
  })
  const [dataDELETE, setDataDELETE] = useState({})
  // const [delData, setDelData] = useState({})
  const [dataPUT, setDataPUT] = useState({
    id: '',
    name: '',
    email: '',
    ramal: '',
    area: '',
    senha: '',
    telefone: '',
  })

  const handleModalNewOpen = () => setIsModalOpen(true)
  // Fetch data when component is rendered
  useEffect(() => {
    setDataGET(fetchData())
  }, [])

  /* BaseClass is a set of standard styles */
  const BaseClass = BaseContainer()

  return (
    <div className={BaseClass.container}>
      <Menu />

      <Grid container component='section' className={BaseClass.containerGrid}>
        <Grid item xs={12} className='header'>
          <DashboardHeader
            title='Usuarios'
            subTitle='Lista de usuarios do sistema'
          />
        </Grid>

        <Grid item xs={12}>
          <TableActions
            btnLabel='Novo Usuario'
            btnAction={handleModalNewOpen}
          />
        </Grid>

        <Grid item xs={12} className='user-table'>
          <DashboardTable
            columns={tableColumns}
            data={dataGET}
            setDataEdit={setDataPUT}
            setIsEditOpen={setIsModalEditOpen}
            setDataDelete={setDataDELETE}
            setIsDeleteOpen={setIsModalDeleteOpen}
          />
        </Grid>
      </Grid>

      <UsersModalNew
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        data={dataPOST}
        setData={setDataPOST}
      />

      <UsersModalEdit
        isOpen={isModalEditOpen}
        setIsOpen={setIsModalEditOpen}
        data={dataPUT}
        setData={setDataPUT}
      />

      <UsersModalDelete
        isOpen={isModalDeleteOpen}
        setIsOpen={setIsModalDeleteOpen}
        data={dataDELETE}
      />
    </div>
  )
}

export default Users
