import React from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { Link as LinkLogin } from 'react-router-dom'

import './loginStyle.scss'

function Login() {
  return (
    <div className='login'>
      <div className='logo' />
      <form>
        <Grid container spacing={2} justify='center'>
          <Grid item xs={12} xl={7}>
            <TextField
              variant='outlined'
              fullWidth
              label='Email'
              type='email'
            />
          </Grid>

          <Grid item xs={12} xl={7}>
            <TextField
              variant='outlined'
              fullWidth
              label='Senha'
              type='password'
            />
          </Grid>

          <Grid item xs={12} xl={7}>
            <LinkLogin to='contratos'>
              <Button
                color='default'
                fullWidth
                variant='contained'
                size='large'>
                Login
              </Button>
            </LinkLogin>
          </Grid>

          <Grid item xs={12} xl={7}>
            <Grid container justify='space-between' alignItems='center'>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Checkbox color='default' />}
                  label='Manter Conectado'
                />
              </Grid>

              <Grid item xs={6}>
                <Typography align='right'>
                  <Link href='#'>Esqueceu sua senha?</Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <div className='side-img'></div>
    </div>
  )
}

export default Login
