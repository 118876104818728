import React, { useState } from 'react'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import { makeStyles } from '@material-ui/core/styles'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#fafafa',
    },
  },
})

const Row = props => {
  const { children, columns, data, onClick, setRowDataDetailed } = props

  // State for the collapsible part of the row
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)

  const handleCollapse = () => setIsCollapseOpen(!isCollapseOpen)

  const handleDetailed = () => {
    onClick('detailed')
    setRowDataDetailed(data)
  }
  const handleEdit = () => {
    onClick('edit')
    setRowDataDetailed(data)
  }
  const handleDelete = () => onClick('delete')

  const classes = useRowStyles()

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell style={{ width: '30px' }}>
          <IconButton size='small' onClick={handleCollapse}>
            {isCollapseOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>

        {
          // Map each column and retrives the data with the key in each column
          columns.map(column => (
            // Key prop recives a random id just for avoid conflicts
            <TableCell
              key={Math.random()}
              align={column.align}
              onClick={handleDetailed}>
              {data[column.key]}
            </TableCell>
          ))
        }

        <TableCell align='center'>
          <IconButton size='small' onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </TableCell>

        <TableCell align='center'>
          <IconButton size='small' onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        {/* colSpan needs to be the array length + 2 because of the delete
          and edit buttons + 1 because of the expand button */}
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length + 3}>
          <Collapse in={isCollapseOpen} timeout='auto' unmountOnExit>
            <Box margin={1}>{children}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default Row
