import React, { useState } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField'

export default function Select({ jsonList, labelName, nameInput, data, setData, jsonObject }) {


    const [inputValue, setInputValue] = useState("");

    return (
        <Autocomplete
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
                setData({
                    ...data,
                    [jsonObject]: newValue
                });
            }}
            value={data[jsonObject]}
            freeSolo
            disableClearable
            options={jsonList.map((option) => option[nameInput])} // 2 jsonList NameInput
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={labelName} //Nome label
                    name={jsonObject}
                    fullWidth
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                />
            )}
        />
    );

}
Select.defaultProps = {
    nameInput: ""
}
                        //Exemplo de utilização do componente
                        // <Select
                        //     jsonList={typestep}
                        //     nameInput="name"  //map option.
                        //     labelName="Pregão/SRP/SL"   //nome do label
                        //     data={data}  
                        //     setData={setData}
                        //     jsonObject="stepstype" // data.stepstype
                        // />  