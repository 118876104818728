import React from 'react'

import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import ModalTable from './ModalTable'

const ModalContent = props => {
  const { type, data, setData } = props

  // checks type, if is true then text field will be read only
  const isReadOnly = field => {
    if (type === 'detailed' || field === 'id') {
      return true
    }
    return false
  }

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  let content
  if (type !== '') {
    if (type === 'detailed' || type === 'edit') {
      content = (
        <Grid container spacing={2} justify='center'>
          <Grid item xs={1}>
            <TextField
              label='Id'
              name='id'
              fullWidth
              variant='outlined'
              inputProps={{ readOnly: isReadOnly('id') }}
              value={data.id}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={7}>
            <TextField
              label='Item'
              name='item'
              fullWidth
              variant='outlined'
              inputProps={{ readOnly: isReadOnly() }}
              value={data.item}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={2}>
            {/* Needs to be a auto complete if in edit mode */}
            <TextField
              label='Tipo do Item'
              name='itemType'
              fullWidth
              variant='outlined'
              inputProps={{ readOnly: isReadOnly() }}
              value={data.itemType}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={2}>
            {/* Needs to be a auto complete if in edit mode */}
            <TextField
              label='Tipo de Contrato'
              name='contractType'
              fullWidth
              variant='outlined'
              inputProps={{ readOnly: isReadOnly() }}
              value={data.contractType}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            {/* Needs to be a auto complete if in edit mode */}
            <TextField
              label='Responsavel'
              name='assignedTo'
              fullWidth
              variant='outlined'
              inputProps={{ readOnly: isReadOnly() }}
              value={data.assignedTo}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={3}>
            {/* Needs to be a date picker if in edit mode */}
            <TextField
              label='Data de Inicio'
              name='startDate'
              fullWidth
              variant='outlined'
              inputProps={{ readOnly: isReadOnly() }}
              value={data.startDate}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={3}>
            {/* Needs to be a date picker if in edit mode */}
            <TextField
              label='Data de Finalização'
              name='endDate'
              fullWidth
              variant='outlined'
              inputProps={{ readOnly: isReadOnly() }}
              value={data.endDate}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label='Observações'
              name='observations'
              fullWidth
              variant='outlined'
              value={data.observations}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Grid>

          <Grid item xs={12}>
            <ModalTable type={type} data={data} setData={setData} />
          </Grid>
        </Grid>
      )
    }

    if (type === 'delete') {
      content = 'DELETE'
    }
  }

  return <DialogContent>{content}</DialogContent>
}

ModalContent.defaultProps = {
  type: '',
  data: {
    data: {},
  },
}

export default ModalContent
