import React from 'react'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  grid: {
    marginBottom: '20px',
  },
  btnWrapper: {
    display: 'flex',
  },
  btn: {
    width: '100%',
  },
})

const TableActions = props => {
  const { btnLabel, btnAction, btn } = props

  const classes = useStyles()

  let textFieldSize = 12
  let btnComponent

  if (btn) {
    btnComponent = (
      <Grid item xs={3} className={classes.btnWrapper}>
        <Button
          variant='outlined'
          size='small'
          className={classes.btn}
          onClick={btnAction}>
          {btnLabel}
        </Button>
      </Grid>
    )
    textFieldSize = 9
  }

  return (
    <Grid container spacing={2} className={classes.grid}>
      <Grid item xs={textFieldSize}>
        <TextField
          variant='outlined'
          size='small'
          fullWidth
          label='Pesquisar'
        />
      </Grid>
      {btnComponent}
    </Grid>
  )
}

TableActions.defaultProps = {
  btn: true,
}

export default TableActions
