import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'

import ProductModalDelete from './ProductModalDelete/ProductModalDelete'
import productlist from '../../data/productlist.json'
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader'
import DashboardTable from '../../components/DashboardTable/DashboardTable'
import TableActions from '../../components/TableActions/TableActions'
import Menu from '../../components/Menu/Menu'
import ProductModalNew from './ProductModalNew/ProductModalNew'
import ProductModalEdit from './ProductModalEdit/ProductModalEdit'

import { BaseContainer } from '../../assets/BaseStyles'

const tableColumns = [
  {
    label: 'Id',
    key: 'id',
    align: 'center',
    width: '5%',
  },
  {
    label: 'Nome',
    key: 'name',
    align: 'left',
    width: '',
  },
  {
    label: 'Tipo de Serviço',
    key: 'typeservice',
    align: 'center',
    width: '',
  },
  {
    label: 'Fornecedor',
    key: 'fornecedor',
    align: 'center',
    width: '',
  },
]

function fetchData() {
  return productlist
}

function Product() {
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [dataGET, setDataGET] = useState([])
  const [dataPUT, setDataPUT] = useState({
    id: '',
    name: '',
    typeservice: '',
    fornecedor: '',
    description: '',
  })
  const [dataDELETE, setDataDELETE] = useState({
    id: '',
  })
  const [dataPOST, setDataPOST] = useState({
    name: '',
    typeservice: '',
    fornecedor: '',
    description: '',
  })

  useEffect(() => {
    setDataGET(fetchData())
  }, [])

  /* BaseClass is a set of standard styles */
  const BaseClass = BaseContainer()

  const handleModalNewOpen = () => setIsModalOpen(true)

  return (
    <div className={BaseClass.container}>
      <Menu />

      <Grid container className={BaseClass.containerGrid}>
        <Grid item xs={12} className='product-header'>
          <DashboardHeader
            title='Produtos'
            subTitle='Lista de produtos do sistema'
          />
        </Grid>
        <Grid item xs={12}>
          <TableActions
            btnLabel='Novo Produto'
            btnAction={handleModalNewOpen}
          />
        </Grid>
        <Grid item xs={12} className='product-table'>
          <DashboardTable
            columns={tableColumns}
            data={dataGET}
            setDataEdit={setDataPUT}
            setIsEditOpen={setIsModalEditOpen}
          />
        </Grid>
      </Grid>

      <ProductModalNew
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        data={dataPOST}
        setData={setDataPOST}
      />

      <ProductModalDelete
        isOpen={isModalDeleteOpen}
        setIsOpen={setModalDeleteOpen}
        data={dataDELETE}
        setData={setDataDELETE}
      />
      <ProductModalEdit
        isOpen={isModalEditOpen}
        setIsOpen={setIsModalEditOpen}
        data={dataPUT}
        setData={setDataPUT}
      />
    </div>
  )
}

export default Product
