import React, { useState } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'



function ServiceTypeModalNew({ isOpen, setIsOpen, data, setData }) {

    const [confirmSenha, setConfirmSenha] = useState("");

    const handleClose = () => {
        setData({
            serviceType: '',
        })
        
        setConfirmSenha('');
        
        setIsOpen(false)
    }

    const handleDataPost = () => {

        if (confirmSenha !== data.senha) {
            alert("senhas diferentes")
        } else {
            console.log(data);
            handleClose();
        }

    }

    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Dialog name='modalNew' open={isOpen} onClose={handleClose} fullWidth maxWidth='md'>

            <DialogTitle>Cadastro de Tipo de Serviço</DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name='serviceType'
                            label='Tipo de Serviço'
                            fullWidth
                            variant='outlined'
                            value={data.serviceType}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button name='modalNew' onClick={handleClose} color='default'>
                    Fechar
                </Button>

                <Button
                    name='modalNew'
                    onClick={handleDataPost}
                    color='default'
                    autoFocus>
                    Salvar
                </Button>
            </DialogActions>

        </Dialog>
    );


}
export default ServiceTypeModalNew