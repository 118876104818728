import React from 'react'

import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'

import ModalTableSelect from './ModalTableSelect'

const selectOptions = ['Pendente', 'Finalizado']

const ModalTable = props => {
  const { type, data, setData } = props

  const HandleStatus = rowData => {
    if (type === 'detailed') {
      return rowData.status
    }

    if (type === 'edit') {
      return (
        <ModalTableSelect
          value={rowData.status}
          options={selectOptions}
          data={data}
          setData={setData}
          rowId={rowData.id}
        />
      )
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center' style={{ width: '50px' }}>
              Id
            </TableCell>
            <TableCell align='left'>Fase</TableCell>
            <TableCell align='center'>Status</TableCell>
            <TableCell align='center'>Inicio</TableCell>
            <TableCell align='center'>Prazo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.steps.map(row => (
            <TableRow key={`${row.step}-${Math.random()}`}>
              <TableCell align='center'>{row.id}</TableCell>
              <TableCell align='left' style={{ width: '60%' }}>
                {row.step}
              </TableCell>
              <TableCell align='center'>{HandleStatus(row)}</TableCell>
              <TableCell align='center'>{row.startDate}</TableCell>
              <TableCell align='center'>{row.endDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ModalTable
