import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import logo from '../../assets/logo.png'
import gear from '../../assets/gear.svg'

import './index.css'

const drawerWidth = 225

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(80% - ${drawerWidth}px)`,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#efefef',
  },
  logo: {
    maxWidth: '160px',
    marginTop: '16px',
    marginLeft: '16px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItemText: {
    fontSize: '1.1em',
    fontWeight: '300',
    color: '#424242',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#B3B3B3',
    },
  },
  iconButton: {
    maxHeight: '50px',
    margin: '5px',
  },
}))

const options = [
  { key: 2, name: 'Contratos', path: 'contratos' },
  { key: 3, name: 'Fases', path: 'fases' },
  { key: 4, name: 'Areas', path: 'areas' },
  { key: 5, name: 'Usuarios', path: 'usuarios' },
  { key: 6, name: 'Demandas', path: 'demandas' },
  { key: 7, name: 'Produtos', path: 'produtos'},
  { key: 8, name: 'Fornecedores', path: 'fornecedores' },
  { key: 9, name: 'Serviços', path: 'servicos'}
]

const Menu = props => {
  const { window } = props

  const [mobileOpen, setMobileOpen] = useState(false)

  const classes = useStyles()
  const theme = useTheme()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div className='menuAll'>
      <img src={logo} alt='logo' className={classes.logo} />

      <div className='menuAlign'>
        <List style={{ width: '100%' }}>
          {options.map(option => (
            <Link key={option.key} to={option.path}>
              <ListItem button className={classes.tableRow} key={option}>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={option.name}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>

      <div>
        <List className={classes.userAlign}>
          <Divider />
          <ListItem>
            <ListItemAvatar>
              <Avatar />
            </ListItemAvatar>
            <ListItemText
              style={{ color: '424242' }}
              primary='Rogério Maria'
              secondary={
                <Typography
                  component='span'
                  variant='body2'
                  className={classes.inline}>
                  Admin
                </Typography>
              }
            />
            <img className='gear' src={gear} alt='gear' />
          </ListItem>
        </List>
      </div>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <div>
      <IconButton
        color='inherit'
        edge='end'
        onClick={handleDrawerToggle}
        className={classes.iconButton}>
        <MenuIcon style={{ fontSize: '32px' }} />
      </IconButton>
      <nav className={classes.drawer}>
        <Hidden smUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: false,
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='permanent'
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
}
export default Menu
