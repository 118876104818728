import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'

import stepsData from '../../data/steps.json'
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader'
import DashboardTable from '../../components/DashboardTable/DashboardTable'
import TableActions from '../../components/TableActions/TableActions'
import StepsModalNew from '../Steps/StepsModalNew/StepsModalNew'
import StepsModalEdit from '../Steps/StepsModalEdit/StepsModalEdit'
import StepsModalDelete from './StepsModalDelete/StepsModalDelete'
import Menu from '../../components/Menu/Menu'

import { BaseContainer } from '../../assets/BaseStyles'

const tableColumns = [
  {
    label: 'Id',
    key: 'id',
    align: 'center',
    width: '5%',
  },
  {
    label: 'Número',
    key: 'number',
    align: 'left',
    width: '',
  },
  {
    label: 'Tipo',
    key: 'type',
    align: 'center',
    width: '',
  },
  {
    label: 'Categoria',
    key: 'category',
    align: 'center',
    width: '',
  },
  {
    label: 'Fase',
    key: 'step',
    align: 'left',
    width: '',
  },
]
const fetchData = () => {
  return stepsData
}

function Steps() {
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [dataGET, setDataGET] = useState([])
  const [dataPOST, setDataPOST] = useState({
    number: '',
    type: '',
    category: '',
    step: '',
  })
  const [dataDELETE, setDataDELETE] = useState({})
  const [dataPUT, setDataPUT] = useState({
    number: '',
    type: '',
    category: '',
    step: '',
  })

  const handleModalNewOpen = () => setIsModalOpen(true)
  // Fetch data when component is rendered
  useEffect(() => {
    setDataGET(fetchData())
  }, [])

  /* BaseClass is a set of standard styles */
  const BaseClass = BaseContainer()

  return (
    <div className={BaseClass.container}>
      <Menu />

      <Grid container component='section' className={BaseClass.containerGrid}>
        <Grid item xs={12} className='header'>
          <DashboardHeader title='Fases' subTitle='Lista de fases do sistema' />
        </Grid>

        <Grid item xs={12}>
          <TableActions btnLabel='Nova fase' btnAction={handleModalNewOpen} />
        </Grid>

        <Grid item xs={12} className='steps-table'>
          <DashboardTable
            columns={tableColumns}
            data={dataGET}
            setDataEdit={setDataPUT}
            setIsEditOpen={setIsModalEditOpen}
            setDataDelete={setDataDELETE}
            setIsDeleteOpen={setIsModalDeleteOpen}
          />
        </Grid>
      </Grid>

      <StepsModalNew
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        data={dataPOST}
        setData={setDataPOST}
      />

      <StepsModalEdit
        isOpen={isModalEditOpen}
        setIsOpen={setIsModalEditOpen}
        data={dataPUT}
        setData={setDataPUT}
      />

      <StepsModalDelete
        isOpen={isModalDeleteOpen}
        setIsOpen={setIsModalDeleteOpen}
        data={dataDELETE}
      />
    </div>
  )
}

export default Steps
