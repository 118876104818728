import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  select: {
    fontSize: '0.875rem',
  },
}))

const ModalTableSelect = props => {
  const { value, options, data, setData, rowId } = props

  const classes = useStyles()

  const handleChange = e => {
    /* data and setData are used to map the options  */
    setData({
      ...data,
      steps: data.steps.map(step => ({
        ...step,
        status: step.id === rowId ? e.target.value : step.status,
      })),
    })
  }

  return (
    <FormControl size='small' className={classes.formControl}>
      <Select
        value={value}
        onChange={handleChange}
        disableUnderline
        className={classes.select}>
        {options.map(option => (
          <MenuItem key={`${option}-${Math.random()}`} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

ModalTableSelect.defaultProps = {
  value: '',
  options: [],
}

export default ModalTableSelect
